import styles from '@/styles/Home.module.css';
import { useState } from 'react';

import FAQRow from './faqrow';

const FAQArr = [
    {cat: 'Token BSKT', arr: [
        {id: 1, q: 'Can I buy BSKT tokens?', a: <p>Yes, BasketCoin token is now available to trade on PancakeSwap, you can find token via the contract address - 0x4dd1984a706e1c2c227bea67ad2f92dbde30afce <br /> <br /> You can also use the direct link to BSKT/BUSD pair: https://pancakeswap.finance/swap?inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&outputCurrency=0x4dd1984a706e1c2c227bea67ad2f92dbde30afce</p>},
        {id: 2, q: 'Will the maximum supply of BSKT in circulation ever change?', a: <p>No, the maximum amount of BSKT in circulation will never be increased and new BSKT tokens will not appear on the market.</p>},
        {id: 3, q: 'What is the starting and ending amount of BSKT in circulation?', a: <p>The initial supply is 21 million (21,000,000) BSKT. The final supply will be 2.1 million (2,100,000) BSKT tokens.</p>}
    ]},
    {cat: 'Staking', arr: [
        {id: 4, q: 'How many tokens can I stake?', a: <p>The minimum number of tokens necessary to stake on the platform is 5,000 BSKT. The upper limit is not specified.</p>},
        {id: 5, q: 'How is redistribution working?', a: <p>With the launch of Staking Platform all tokens that have been collected in the form of a 1% fee on the total value of all transactions based on BSKT in the previous month will go to the redistribution pool. Then, this pool will be distributed throughout the following month to all stakers on the platform - proportionally. This process will be repeated monthly.</p>},
    ]}
]

const FAQ = () => {

    const [category, setCategory] = useState(0);

    return (
        <div className={styles.faqwrapper}>
            <div>
                <div className={styles.categorieswrapper}>
                    <div className={styles.categories}>
                        {FAQArr?.map((o, i) => {
                            return (
                                <div key={i} className={styles.category} onClick={e => {setCategory(i)}}>
                                    <p>{o?.cat}</p>
                                    <div className={`${styles.selection} ${category === i ? styles.selected : ''}`} />
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div className={styles.faq}>
                    {FAQArr[category]?.arr?.map(o => {
                        return (
                            <FAQRow key={o?.id} o={o} />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default FAQ