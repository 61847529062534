import styles from '@/styles/Ari.module.css';
import Image from 'next/image';
import { useEffect, useState, useCallback } from 'react';
import Script from 'next/script';

import Fiat from './fiat';
import Button from '@/components/External/button';

const Ari = ({ custom }) => {

    const [currency, setCurrency] = useState('BSKT');
    const [amount, setAmount] = useState(500);
    const [res, setRes] = useState(null);
    const [disabled, setDisabled] = useState(null);
    const [fiat, setFiat] = useState('USD');


    const rates = useCallback(async () => {
        try {
          const requestOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Ari10-Widget-Id': 'aa044660-e663-444f-bc28-87e007c03e78',
            },
            body: JSON.stringify({
              offeredAmount: amount,
              offeredCurrencyCode: fiat,
            }),
          };
      
          const arires = await fetch(
            `https://gateway.ari10.com/currencies/${currency}/calculate`,
            requestOptions
          );
      
          const ari = await arires.json();
          if(ari?.code === 'BNB' ) {
            setRes({'code': ari?.code, 'amount': ari?.amount.toFixed(6)})
          } else {
            setRes({'code': ari?.code, 'amount': ari?.amount.toFixed(2)})
          }
        } catch (err) {
          setDisabled(true);
        }
    }, [amount, currency, fiat]);

    const start = useCallback((event) => {
        event.preventDefault();
        if (!disabled) {
          window.dispatchEvent(
            new CustomEvent('ari10-widget-start-transaction-request', {
              detail: {
                buyCurrencyCode: currency ?? 'BSKT',
                offerMoney: {
                  amount,
                  currencyCode: fiat ?? 'USD',
                },
              },
            })
          );
        }
    }, [amount, currency, disabled, fiat]);

    const handleInput = (e) => {
        if(e.target.value >= 100 && e.target.value <= 10000) {
            setAmount(parseFloat(e.target.value))
            setDisabled();
        } else {
            setDisabled(true);
        }
    }

    useEffect(() => {
        
        rates();

        const intervalId = setInterval(() => {
            rates();
        }, 10000);
        
        return () => {
            clearTimeout(intervalId);
        };

    }, [fiat, amount, currency])

    return ( 
        <>
            <Script strategy="beforeInteractive">var widget_id_6851681344231 = "aa044660-e663-444f-bc28-87e007c03e78"</Script>
            <Script strategy="beforeInteractive" src="https://gateway.ari10.com/widget/main.min.js" />
            <form onSubmit={start} className={`${styles.panelwrapper} ${custom ? styles.custom : null} `}>
                <div className={styles.Type} aria-label='Buy cryptocurrencies for fiat, using different payment methods. For example Credit Card'>
                  <Button custom={styles.optwrapper}>
                    <div className={`${styles.option} ${styles.selected}`}>
                        <span>Buy</span>
                    </div>
                  </Button>
                    <div className={`${styles.optwrapper} ${styles.disabled}`}>
                        <span>Swap</span>
                    </div>
                </div>
                <div className={styles.Currency}>
                    
                    <div>

                        <Button custom={styles.optwrapper}>
                          <button type='button' className={`${styles.option} ${(currency === 'BNB') ? styles.selected : null}`}
                              onClick={() => {setCurrency('BNB')}} aria-label={`${currency === 'BNB' ? 'Binance Coin is currently selected' : 'Select Binance Coin token'}`}>
                              <span>BNB</span>
                              <div className={styles.imgwrapper}><Image src='/BNBIcon.png' alt='BNBIcon' height={22} width={22}></Image></div>
                          </button>
                        </Button>
                        <Button custom={styles.optwrapper}>
                          <button type='button' className={`${styles.option} ${(currency === 'USDC') ? styles.selected : null}`}
                              onClick={() => {setCurrency('USDC')}} aria-label={`${currency === 'USDC' ? 'USDC is currently selected' : 'Select Binance USDC'}`}>
                              <span>USDC</span>
                              <div className={styles.imgwrapper}><Image src='/USDCIcon.png' alt='USDCIcon' height={22} width={22}></Image></div>
                          </button>
                        </Button>

                    </div>

                    <div>

                      <Button custom={styles.optwrapper}>
                          <button type='button' className={`${styles.option} ${(currency === 'BSKT' || !currency) ? styles.selected : null}`}
                              onClick={() => {setCurrency('BSKT')}} aria-label={`${currency === 'BSKT' || !currency ? 'BasketCoin (BSKT) is currently selected' : 'Select BasketCoin (BSKT) token'}`}>
                              <span>BSKT</span>
                              <div className={styles.imgwrapper}><Image src='/round_logo.png' alt='RoundLogo' height={22} width={22}></Image></div>
                          </button>
                      </Button>

                    </div>

                </div>

                <div className={styles.Offer}>
                    
                    <label htmlFor="cash">for</label>

                    <input type="number" name="cash" id="cash" onChange={handleInput} defaultValue={500} aria-label={`Enter the purchase amount. Currently selected ${amount} ${fiat} `} />
                    
                    <Fiat setFiat={setFiat} fiat={fiat} />

                </div>
                <Button custom={`white ${styles.buywrapper}`}>
                  <button className={`${styles.Buy} ${disabled && styles.disabled}`} onClick={start} type='submit' aria-label={`Buy ${res?.amount} ${res?.code} for ${amount} ${fiat}. Submit form`}>
                    <p>Buy {`${res?.amount ?? 0} ${res?.code ?? 'BSKT'}`}</p>
                  </button>
                </Button>
            </form>
        </>
     );
}
 
export default Ari;
