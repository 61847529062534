import { useState } from 'react'
import styles from '@/styles/Ari.module.css'
import Image from 'next/image';

const Fiat = ({ fiat, setFiat }) => {

    const [list, setList] = useState(false);

  return (
    
                    
    <button type='button' className={styles.fiat} onClick={() => {list ? setList(false) : setList(true)}} 
        onBlur={(e) => {if (!e.currentTarget.contains(e.relatedTarget)) {setList(false)}}} aria-label={`Expand fiat currency list. ${fiat} selected `} aria-controls='list'>
                        
        <Image src={`/${fiat}Icon.svg`} alt='FiatIcon' width={22} height={22} />
        <span>{fiat ? fiat : 'USD'}</span>
        <svg fill='currentColor' width="22px" viewBox="0 0 960 560">
            <g>
                <path d="M480,344.181L268.869,131.889c-15.756-15.859-41.3-15.859-57.054,0c-15.754,15.857-15.754,41.57,0,57.431l237.632,238.937
                    c8.395,8.451,19.562,12.254,30.553,11.698c10.993,0.556,22.159-3.247,30.555-11.698l237.631-238.937
                    c15.756-15.86,15.756-41.571,0-57.431s-41.299-15.859-57.051,0L480,344.181z"/>
            </g>
        </svg>

        <div className={`${styles.list} ${list ? styles.active : null}`} id='list' aria-expanded={list ?? false} aria-label='Fiat currency list'>
            {fiat !== 'PLN' ? <div tabIndex={0} onKeyDown={(e) => {e.key === 'Enter' ? setFiat('PLN') : null}} onClick={() => {setFiat('PLN')}} aria-label='Select currency Polish Zloty'>
                <Image src='/PLNIcon.svg' alt='PLNIcon' width={22} height={22}></Image>
                <span>PLN</span>
            </div> : null}
            {fiat !== 'USD' ? <div tabIndex={0} onKeyDown={(e) => {e.key === 'Enter' ? setFiat('USD') : null}} onClick={() => {setFiat('USD')}} aria-label='Select currency US Dollar'>
                <Image src='/USDIcon.svg' alt='USDIcon' width={22} height={22}></Image>
                <span>USD</span>
            </div> : null}
            {fiat !== 'EUR' ? <div tabIndex={0} onKeyDown={(e) => {e.key === 'Enter' ? setFiat('EUR') : null}} onClick={() => {setFiat('EUR')}} aria-label='Select currency Euro'>
                <Image src='/EURIcon.svg' alt='EURIcon' width={22} height={22}></Image>
                <span>EUR</span>
            </div> : null}
        </div>

    </button>
  )
}

export default Fiat